import { Injectable } from "@angular/core";
import { ToolsBoxService } from "./tools.service";
import { User } from "../Models/user.component";
import { BalanceHolder } from "../Models/Holders/balanceholder.component";
import { CalendarEventHolder } from "../Models/Holders/calendareventholder.component";
import { BillingHolder } from "../Models/Holders/billingholder.component";
import { PlanningItem } from "../Models/planningitem.component";
import { CallApi, HTTP_COMMAND } from "./callApi";
import { SocialEventRequest } from "../Models/Holders/SocialEventRequest.component";
import { TechRadarDataHolder } from "../Models/Holders/techRadarDataHolder.component";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
@Injectable()
export class UserService {
  constructor(private callAPI: CallApi, private toolsBox: ToolsBoxService) {}
  /**
   * USER ADMINISTRATION
   */
  //Used for Directory
  getUserList = (): Observable<User[]> => {
    return this.callAPI.call(HTTP_COMMAND.GET, "/users/");
  };
  //Used for Settings
  getUserInformation = (user: User): Observable<User> => {
    return this.callAPI.call(HTTP_COMMAND.GET, "/users/", user.id);
  };
  //Used for Settings
  updateUserInformation = (
    newUser: User,
    password: String
  ): Observable<boolean> => {
    if (password) {
      newUser.userSalt = this.toolsBox.generateRandomString(15);
      newUser.userPassword =
        "" + this.toolsBox.convertPassword(password, newUser.userSalt);
    }
    return this.callAPI
      .call(
        HTTP_COMMAND.PUT,
        "/users/" + this.toolsBox.getStoredUserId(),
        newUser
      )
      .pipe(map((data) => true));
  };
  //Used when changing password
  updateUserPwd = (
    userlogin: string,
    password: string
  ): Observable<boolean> => {
    let newSalt = this.toolsBox.generateRandomString(15);
    let paramUser =
      "{" +
      '"id":' +
      this.toolsBox.getStoredUserId() +
      ',"userLogin":"' +
      userlogin +
      '","userPassword":"' +
      this.toolsBox.convertPassword(password, newSalt) +
      '","userSalt":"' +
      newSalt +
      '"}';
    return this.callAPI
      .call(
        HTTP_COMMAND.PUT,
        "/users/" + this.toolsBox.getStoredUserId() + "/password/",
        paramUser
      )
      .pipe(map(() => true));
  };
  //Used for Dashboard
  getUserBalance = (
    userId: number,
    selectedYear: number
  ): Observable<BalanceHolder> => {
    let serviceURi = "/balances/" + selectedYear;
    if (userId !== null) {
      serviceURi = "/manager/balances/user/" + userId + "/year/" + selectedYear;
    }
    return this.callAPI.call(HTTP_COMMAND.GET, serviceURi);
  };
  /**
   * SOCIAL EVENT ADMINISTRATION
   */
  handleEventInvitation = (
    event: CalendarEventHolder
  ): Observable<PlanningItem[]> => {
    return this.callAPI.call(
      HTTP_COMMAND.PUT,
      "/social-events/" + event.eventId,
      event
    );
  };
  /**
   * TIME SHEETS
   */
  getUserBilledHours = (
    user: User,
    selectedYear: number
  ): Observable<BillingHolder> => {
    let serviceURi = "/timesheets/" + selectedYear;
    if (user !== null) {
      serviceURi =
        "/admin/timesheets/user/" + user.id + "/year/" + selectedYear;
    }
    return this.callAPI
      .call(HTTP_COMMAND.GET, serviceURi)
      .pipe(map((data) => new BillingHolder(data)));
  };

  getTimesheetExport = (year: number, date: string): Observable<void> => {
    let month = date.substring(5);
    let serviceURi = "/timesheets/export/" + year + "/" + month;

    return this.callAPI.call(HTTP_COMMAND.GET, serviceURi, null, "blob").pipe(
      map((data) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const blob: any = new Blob([data], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "TS-" + date + ".xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      })
    );
  };

  /**
   * EVENT ADMINISTRATION
   */

  //Used for Time Management
  sendUserEvent = (holder: CalendarEventHolder): Observable<boolean> => {
    if (holder.eventId > 0) {
      return this.callAPI
        .call(HTTP_COMMAND.PUT, "/events/" + holder.eventId, holder)
        .pipe(map((response) => true));
    } else {
      return this.callAPI
        .call(HTTP_COMMAND.POST, "/events/", holder)
        .pipe(map((response) => true));
    }
  };

  //Used for Time Management and Overview
  getUserEvent = (user: User): Observable<CalendarEventHolder[]> => {
    let serviceURi = "/events/";
    if (user !== null) {
      serviceURi = "/manager/events/user/" + user.id;
    }
    return this.callAPI.call(HTTP_COMMAND.GET, serviceURi);
  };

  //Used for Time Management and Overview
  getUserPartTime = (user: User): Observable<CalendarEventHolder[]> => {
    let serviceURi = "/manager/events/user/" + user.id + "/days-off";
    return this.callAPI.call(HTTP_COMMAND.GET, serviceURi);
  };

  /**
   * TECH RADAR
   */
  getTechRadarData = (user: User): Observable<any> => {
    let serviceURi = "/techradar/";
    if (user !== null) {
      serviceURi = "/coordinator/techradar/" + user.id;
    }
    return this.callAPI
      .call(HTTP_COMMAND.GET, serviceURi)
      .pipe(map((data) => new TechRadarDataHolder(data)));
  };
  updateSkills = (data: TechRadarDataHolder): Observable<boolean> => {
    let serviceURi = "/techradar/";
    return this.callAPI.call(HTTP_COMMAND.PUT, serviceURi, data);
  };
  getTechRadaStat = (): Observable<any> => {
    let serviceURi = "/coordinator/stats/";
    return this.callAPI.call(HTTP_COMMAND.GET, serviceURi);
  };
  /**
   * ADMIN PART
   */
  //Used for Time Management
  getPendingRequest = (): Observable<CalendarEventHolder[]> => {
    return this.callAPI.call(HTTP_COMMAND.GET, "/admin/requests");
  };
  //Used for Time Management / ADMIN
  manageRequest = (holder: CalendarEventHolder): Observable<boolean> => {
    return this.callAPI
      .call(HTTP_COMMAND.PUT, "/admin/requests/" + holder.eventId, holder)
      .pipe(map((response) => true));
  };
  //Used for User Management
  getItzList = (): Observable<User[]> => {
    return this.callAPI.call(HTTP_COMMAND.GET, "/admin/itz/");
  };
  //Used for Admin Management
  createOrUpdateITz = (newUser: User): Observable<string> => {
    if (newUser.id > 0) {
      return this.callAPI
        .call(HTTP_COMMAND.PUT, "/admin/itz/" + newUser.id, newUser)
        .pipe(map((response) => "This account has been updated"));
    } else {
      return this.callAPI
        .call(HTTP_COMMAND.POST, "/admin/itz/", newUser)
        .pipe(map((response) => "This account has been created"));
    }
  };
  //create Social Events
  createEvents = (request: SocialEventRequest): Observable<boolean> => {
    return this.callAPI
      .call(HTTP_COMMAND.POST, "/admin/social-events/", request)
      .pipe(map((response) => true));
  };
  //Used for reseting user password
  resetUserPassword = (user: User, password: String): Observable<number> => {
    return (
      this.callAPI
        .call(
          HTTP_COMMAND.PUT,
          "/admin/itz/" + user.id + "/password/",
          '{"userLogin":"' +
            user.userLogin +
            '",' +
            '"id":"' +
            user.id +
            '", ' +
            '"userPassword":"' +
            password +
            '", ' +
            '"userPasswordTmp":"' +
            user.userPasswordTmp +
            '"' +
            "}"
        )
        //Convert the Observable<Response> to a Json Object
        .pipe(map((response) => 0))
    );
  };
  getSocialEventPlanning = (): Observable<PlanningItem[]> => {
    let serviceURi = "/social-events/";
    if (this.toolsBox.isEventManager()) {
      serviceURi = "/coordinator/planning/";
    }
    return this.callAPI.call(HTTP_COMMAND.GET, serviceURi);
  };
  //Used for Admin or Manager
  getTeamList = (): Observable<User[]> => {
    return this.callAPI.call(HTTP_COMMAND.GET, "/manager/users/");
  };
}
